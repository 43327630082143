import { CtBatch, CtDocument } from '../models/ct-batch-model';
import {
  LineOrientation,
  positionExtractionHeaders,
  TableAuditPositionRow,
  TableData,
} from '../models/interfaces';
import { maxBy, minBy } from '../utils/Array.utils';
import {
  BuildSimpleExtractedFieldDto,
  BuildSimpleExtractedTableRowDto,
  BuildSimpleExtractionResultDto,
  BuildSimplePageLocation,
} from './build-simple-model';
import { isDefined } from '../utils/TypeGuard.utils';
import { transformDate } from '../pipes/ocr-date.pipe';
import dayjs from 'dayjs';
import { entries } from '../utils/Record.utils';

export interface BuildSimpleTable {
  dataRows: TableAuditPositionRow[];
  overlayData: TableData;
  documentId: string;
}

const TABLE_NAME = 'forderungen';

export const getBuildSimpleExtractedTables = (
  ctBatch: CtBatch,
  extractionDataByDocumentId: Record<string, BuildSimpleExtractionResultDto>,
): BuildSimpleTable[] =>
  ctBatch.documents
    .filter((document) => document.id in extractionDataByDocumentId)
    .flatMap((document) =>
      getTablesForDocument(document, extractionDataByDocumentId[document.id].groups[TABLE_NAME]),
    );

const getTablesForDocument = (
  document: CtDocument,
  rows: BuildSimpleExtractedTableRowDto[],
): BuildSimpleTable[] => {
  const rowsByPage = Object.groupBy(rows, (row) => Object.values(row.members)[0]!.location.page);

  return entries(rowsByPage).map(([oneBasedPage, rows]) => {
    const pageId = document.pages[oneBasedPage - 1].id;

    const forderungenTableColumns: Record<string, (BuildSimpleExtractedFieldDto | undefined)[]> =
      {};
    for (const row of rows!) {
      for (const [key, field] of Object.entries(row.members)) {
        if (!forderungenTableColumns[key]) forderungenTableColumns[key] = [];

        forderungenTableColumns[key].push(field);
      }
    }

    const tableCellLocations = rows!
      .flatMap((row) => Object.values(row.members).map((field) => field?.location))
      .filter(isDefined);

    const tableBoundary = getBoundingRect(tableCellLocations);

    const horizontalLines = rows!.slice(0, rows!.length - 1).map((row) => ({
      offset: maxBy(Object.values(row.members).filter(isDefined), (field) => field.location.lower)!
        .location.lower,
      orientation: LineOrientation.HORIZONTAL,
    }));

    const tableOverlayData: TableData = {
      pageId: pageId,
      lasso: tableBoundary,
      confirmed: true,
      layoutDefined: true,
      id: `${document.id}_${oneBasedPage}`,
      lines: {
        vertical: [
          { offset: tableBoundary.x[0], orientation: LineOrientation.VERTICAL, isBoundary: true },
          ...Object.values(forderungenTableColumns)
            .slice(0, Object.values(forderungenTableColumns).length - 1)
            .map((fields) => ({
              offset: maxBy(fields.filter(isDefined), (field) => field.location.right)!.location
                .right,
              orientation: LineOrientation.VERTICAL,
            }))
            .toSorted((line1, line2) => line1.offset - line2.offset),
          { offset: tableBoundary.x[1], orientation: LineOrientation.VERTICAL, isBoundary: true },
        ],
        horizontal: [
          {
            offset: tableBoundary.y[0],
            orientation: LineOrientation.HORIZONTAL,
            isBoundary: true,
          },
          ...horizontalLines,
          {
            offset: tableBoundary.y[1],
            orientation: LineOrientation.HORIZONTAL,
            isBoundary: true,
          },
        ],
      },
      columnHeaders: positionExtractionHeaders,
    };

    console.log('vertical lines: ', tableOverlayData.lines.vertical);
    console.log('horizontal lines: ', tableOverlayData.lines.horizontal);

    return {
      dataRows: rowDtosToTableRows(rows!, document).map((tableRow) => ({
        ...tableRow,
        pageId: tableOverlayData.pageId,
        tableId: tableOverlayData.id,
      })),
      documentId: document.id,
      overlayData: tableOverlayData,
    };
  });
};

const getBoundingRect = (locations: BuildSimplePageLocation[]) => {
  const minX = minBy(locations, (location) => location.left)?.left ?? 0;
  const maxX = maxBy(locations, (location) => location.right)?.right ?? 0;
  const minY = minBy(locations, (location) => location.upper)?.upper ?? 0;
  const maxY = maxBy(locations, (location) => location.lower)?.lower ?? 0;

  return { x: [minX, maxX], y: [minY, maxY] } as const;
};

const rowDtosToTableRows = (
  forderungenTableRows: BuildSimpleExtractedTableRowDto[],
  document: CtDocument,
): TableAuditPositionRow[] =>
  forderungenTableRows.map((row) => {
    const description = row.members['forderungen_beschreibung']?.value;
    const from = tryParseDate(row.members['forderungen_von']);
    const to = tryParseDate(row.members['forderungen_bis']);
    const amount = tryParseNumber(row.members['forderungen_betrag']);
    return {
      description: { value: description, id: crypto.randomUUID() },
      from: { value: from, id: crypto.randomUUID() },
      to: { value: to, id: crypto.randomUUID() },
      amount: { value: amount, id: crypto.randomUUID() },
      documentId: document.id,
      positionType: { value: undefined, id: crypto.randomUUID() },
    };
  });

/**
 * The normalized value contains BuildSimple's guess of what the value should be depending
 * on the type. For example, if `value` is `20 11.2021` (missing `.` between `20` and `11`)
 * and the field type is `DATE`, BuildSimple detects that and writes `2021-11-20` to `normalizedValue`.
 * If this should be an invalid date, we try to parse it ourselves.
 */
const tryParseDate = (field: BuildSimpleExtractedFieldDto | undefined): Date | undefined => {
  if (!field) return undefined;

  const buildSimpleDetectedDate = dayjs(field.normalizedValue, 'YYYY-MM-DD');
  if (buildSimpleDetectedDate.isValid()) {
    return buildSimpleDetectedDate.toDate();
  }

  return transformDate(field.value).toDate();
};

const tryParseNumber = (field: BuildSimpleExtractedFieldDto | undefined): number | undefined => {
  if (!field) return undefined;

  const number = Number(field.value);
  if (number && !isNaN(number)) {
    return number;
  }

  return formatMoneyStringToNumber(field.value);
};

const formatMoneyStringToNumber = (moneyString: string): number => {
  const formattedString = moneyString
    // remove all '.' and ',' which are not the last one
    // (i.e. after that comes one more '.' or ',' see https://regexr.com/7v9e5
    .replace(/[.,](?=.*[.,])/g, '')
    .replace(',', '.');
  return parseFloat(formattedString);
};

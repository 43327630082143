import { inject, Injectable } from '@angular/core';
import { PositionType, TableAuditPositionRow, tableHeaderConstants } from '../../models/interfaces';
import { sanitizeDateInput } from '../../utils/SanitizingInput.utils';
import { parseCurrency } from '../../utils/ParseCurrency.utils';
import { OcrDatePipe } from '../../pipes/ocr-date.pipe';
import { ConfirmService } from '@l21s-ecnps/gui-commons';

@Injectable({
  providedIn: 'root',
})
export class TableParseService {
  private ocrDateParser = new OcrDatePipe();
  private confirmService = inject(ConfirmService);

  tableRows: TableAuditPositionRow[] = [];

  handleTableHeaders(
    headerKey: string,
    value: string | PositionType,
    index: number,
    isCtrlPressed = false,
  ) {
    switch (headerKey) {
      case tableHeaderConstants.from:
      case tableHeaderConstants.to:
        this.handleDateField(value, index, headerKey);
        break;
      case tableHeaderConstants.amount:
        this.handleBetragField(value, index);
        break;
      case tableHeaderConstants.type:
        this.handleArtField(value as PositionType, index);
        break;
      default:
        this.handleBeschreibungField(value, index, isCtrlPressed);
    }

    return this.tableRows;
  }

  private handleDateField(joinedString: string, index: number, headerKey: string) {
    const sanitizedString = sanitizeDateInput(joinedString);
    const date = this.ocrDateParser.transform(sanitizedString);
    let newDateValue: Date | undefined = date.toDate();
    let validationError = undefined;
    if (!date.isValid()) {
      if (sanitizedString !== '') {
        this.confirmService.snackError(`Der Wert "${sanitizedString}" ist kein gültiges Datum`);
      }
      newDateValue = undefined;
      validationError = 'Der Wert ist kein gültiges Datum';
    }

    switch (headerKey) {
      case tableHeaderConstants.from:
        this.tableRows[index].from.value = newDateValue;
        this.tableRows[index].from.validationError = validationError;

        break;
      case tableHeaderConstants.to:
        this.tableRows[index].to.value = newDateValue;
        this.tableRows[index].to.validationError = validationError;

        break;
    }
  }

  private handleBetragField(joinedString: string, index: number) {
    // TODO@Lucas: Why is parsing / validation not equivalent?
    const parsedNumber = parseCurrency(joinedString);
    const isInvalid = isNaN(parseFloat(joinedString));
    this.tableRows[index].amount.value = joinedString === '' ? undefined : parsedNumber;
    this.tableRows[index].amount.validationError = isInvalid
      ? 'Der Wert ist kein gültiger amount'
      : undefined;
  }

  private handleArtField(positionType: PositionType, index: number) {
    if (this.tableRows[index].amount.value) {
      if (positionType === PositionType.CREDIT || this.tableRows[index].amount.value < 0) {
        this.negateAmountValue(index);
      }
    }

    this.tableRows[index].positionType.value = positionType;
    this.tableRows[index].positionType.validationError = undefined;
  }
  private negateAmountValue(index: number) {
    this.tableRows[index].amount.value = this.tableRows[index].amount.value! * -1;
  }

  private handleBeschreibungField(joinedString: string, index: number, isCtrlPressed: boolean) {
    const currentValue = this.tableRows[index].description.value;
    const newValue =
      currentValue && isCtrlPressed ? currentValue + ' ' + joinedString : joinedString;
    const isEmpty = newValue.length === 0;
    this.tableRows[index].description.value = isEmpty ? undefined : newValue;
    this.tableRows[index].description.validationError = isEmpty
      ? 'Der Wert ist keine gültige description'
      : undefined;
  }
}

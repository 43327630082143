import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CtBatchApiService } from '../api/ct-batch-api.service';
import { CtBatchStoreService } from './ct-batch-store.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { PersistDataService } from './persist-data.service';
import {
  AppView,
  AuditPatchDto,
  AuditPositionDto,
  AuditPositionWithFieldIndex,
  ExtractedFields,
} from '../../models/interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PersonenschadenApiService } from '../api/personenschaden-api.service';
import dayjs from 'dayjs';
import { ExtractedDataStoreService } from '../extracted-data/extracted-data-store.service';
import { BehaviorSubject, catchError, switchMap, throwError } from 'rxjs';
import { ConfigService } from '../config/config.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  private router = inject(Router);
  private ctBatchStoreService = inject(CtBatchStoreService);
  private ctBatchApiService = inject(CtBatchApiService);
  private confirmService = inject(ConfirmService);
  private persistDataService = inject(PersistDataService);
  private personenschadenApiService = inject(PersonenschadenApiService);
  private extractedDataStoreService = inject(ExtractedDataStoreService);
  private configService = inject(ConfigService);

  private remainingViewQueue: AppView[] = [];
  private viewQueue: AppView[] = [];
  private currentView!: AppView;
  currentForderungsaufstellung$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentViewIndex = 0;
  openCompletionDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  init() {
    if (
      this.persistDataService.viewQueue &&
      this.persistDataService.hasCurrentView &&
      this.persistDataService.currentViewIndex != null &&
      this.persistDataService.currentForderungsaufstellung != null
    ) {
      this.viewQueue = this.persistDataService.viewQueue;
      this.currentViewIndex = this.persistDataService.currentViewIndex;
      this.currentForderungsaufstellung$.next(this.persistDataService.currentForderungsaufstellung);

      this.fillRemainingViewQueue(this.currentViewIndex);

      this.navigateInitially();
      return;
    }

    this.remainingViewQueue.push(AppView.FIELD_EXTRACTION);

    this.ctBatchStoreService.forderungsaufstellungen.forEach(() => {
      this.remainingViewQueue.push(AppView.TABLE_EXTRACTION);
    });

    this.viewQueue = structuredClone(this.remainingViewQueue);
    this.persistDataService.viewQueue = this.viewQueue;
    this.persistDataService.currentForderungsaufstellung = this.currentForderungsaufstellung$.value;

    this.navigateInitially();
  }

  private getPagePathByView(view: AppView): string {
    switch (view) {
      case AppView.FIELD_EXTRACTION:
        return 'fields';
      case AppView.POSITION_CLASSIFICATION:
        return 'position';
      case AppView.TABLE_EXTRACTION:
        return 'tables';
      default:
        throw new Error('This should not happen');
    }
  }

  navigateToAudit() {
    const ctBatchId = this.ctBatchStoreService.ctBatch.id;
    if (!ctBatchId) {
      return;
    }
    const auditId = this.ctBatchStoreService.ctBatch.auditId;

    const subscription = this.sendDataToPersonenschadenApi(auditId)
      .pipe(
        switchMap(() => this.ctBatchApiService.changeCtBatchStateToDone(ctBatchId)),
        catchError((error: unknown) => {
          this.confirmService.snackError('Fehler beim Abschließen des Extraktionsvorgangs.');
          return throwError(() => error);
        }),
      )
      .subscribe({
        next: () => {
          this.persistDataService.reset();
          window.location.replace(`${this.configService.config.auditUrl}/audit/${auditId}`);
        },
      });

    this.confirmService.spinner({ subscription });
  }

  private sendDataToPersonenschadenApi(auditId: string) {
    const extractedFields = structuredClone(this.extractedDataStoreService.extractedFields);
    const auditPositionArray = structuredClone(this.extractedDataStoreService.auditPositionArray);

    const auditPatch = this.createAuditPatchDtoFromStoredData(extractedFields, auditPositionArray);

    return this.personenschadenApiService.patchAudit(auditPatch, auditId);
  }

  private createAuditPatchDtoFromStoredData(
    extractedFields: ExtractedFields,
    auditPositions: AuditPositionWithFieldIndex[],
  ): AuditPatchDto {
    return {
      invoiceDate: extractedFields.InvoiceDate
        ? dayjs(extractedFields.InvoiceDate).format('YYYY-MM-DD')
        : undefined,
      birthDate: extractedFields.ClaimantBirthDate
        ? dayjs(extractedFields.ClaimantBirthDate).format('YYYY-MM-DD')
        : undefined,
      kstSign: extractedFields.KstSign,
      positions: auditPositions.map((auditPosition) => {
        const toDate = auditPosition.to?.value
          ? dayjs(auditPosition.to.value).format('YYYY-MM-DD')
          : dayjs(auditPosition.from?.value).format('YYYY-MM-DD');

        return {
          from: dayjs(auditPosition.from?.value).format('YYYY-MM-DD'),
          to: toDate,
          description: auditPosition.description?.value,
          originalAmount: Number(auditPosition.originalAmount?.value),
          type: auditPosition.type.value,
        } as AuditPositionDto;
      }),
    };
  }

  private navigateInitially() {
    if (this.persistDataService.hasCurrentView) {
      this.navigateToView(this.persistDataService.currentView!);
    } else {
      this.navigateToView(this.remainingViewQueue[0]);
    }
  }

  navigateToPrevView() {
    this.fillRemainingViewQueue(this.currentViewIndex - 1);
    this.currentViewIndex = this.currentViewIndex - 1;
    this.navigateToView(this.remainingViewQueue[0]);
  }

  navigateToNextView(isPositionClassificationNeeded = false) {
    this.modifyViewQueue(isPositionClassificationNeeded);
    if (this.remainingViewQueue.length > 0) {
      this.increaseForderungsaufstellungIndex(isPositionClassificationNeeded);

      this.currentViewIndex = this.currentViewIndex + 1;

      this.navigateToView(this.remainingViewQueue[0]);
    } else {
      this.openCompletionDialog$.next(true);
    }
  }

  private navigateToView(view: AppView) {
    this.remainingViewQueue.shift();
    const pagePath = this.getPagePathByView(view);

    this.router
      .navigate([`batch/${this.ctBatchStoreService.ctBatchId}/${pagePath}`])
      .catch(console.error);
    this.currentView = view;
    this.persistDataService.currentView = this.currentView;
    this.persistDataService.currentViewIndex = this.currentViewIndex;
  }

  resetAppView(): void {
    this.remainingViewQueue.push(...[AppView.FIELD_EXTRACTION, AppView.TABLE_EXTRACTION]);
    this.navigateToNextView();
  }

  isFirstViewInQueue(): boolean {
    return this.currentView === this.viewQueue[0];
  }

  isFieldExtractionView(): boolean {
    return this.currentView === AppView.FIELD_EXTRACTION;
  }

  isTableExtractionView(): boolean {
    return this.currentView === AppView.TABLE_EXTRACTION;
  }

  getCurrentView() {
    return this.currentView;
  }

  isPositionClassificationView(): boolean {
    return this.currentView === AppView.POSITION_CLASSIFICATION;
  }

  private fillRemainingViewQueue(startIndex: number): void {
    this.remainingViewQueue = [];

    for (let i = startIndex; i < this.viewQueue.length; i++) {
      this.remainingViewQueue.push(this.viewQueue[i]);
    }
  }

  private increaseForderungsaufstellungIndex(isPositionClassificationNeeded: boolean) {
    if (
      this.currentView === AppView.POSITION_CLASSIFICATION ||
      (this.currentView === AppView.TABLE_EXTRACTION && !isPositionClassificationNeeded)
    ) {
      this.setAndStoreCurrentForderungsaufstellung(this.currentForderungsaufstellung$.value + 1);
    }
  }

  private modifyViewQueue(isPositionClassificationNeeded: boolean) {
    const indexToLookAt = this.currentViewIndex + 1;
    if (isPositionClassificationNeeded) {
      if (this.remainingViewQueue[0] === AppView.POSITION_CLASSIFICATION) return;
      this.viewQueue.splice(indexToLookAt, 0, AppView.POSITION_CLASSIFICATION);
    } else {
      if (this.remainingViewQueue[0] !== AppView.POSITION_CLASSIFICATION) return;

      this.viewQueue.splice(indexToLookAt, 1);
    }

    this.remainingViewQueue = this.viewQueue.slice(indexToLookAt);
    this.persistDataService.viewQueue = this.viewQueue;
  }

  jumpToView(view: AppView): void {
    this.setAndStoreCurrentForderungsaufstellung(0);
    const newIndex = this.viewQueue.indexOf(view);
    this.fillRemainingViewQueue(newIndex);
    this.currentViewIndex = newIndex;

    this.navigateToView(view);
  }

  setAndStoreCurrentForderungsaufstellung(index: number): void {
    this.currentForderungsaufstellung$.next(index);
    this.persistDataService.currentForderungsaufstellung = index;
  }
}

import { PositionType } from '../models/interfaces';

export function getTranslatedAlphabeticalSortedPositionTypes() {
  return Object.values(PositionType).sort((a, b) => {
    const translationA = getPositionTypeText(a).toLowerCase();
    const translationB = getPositionTypeText(b).toLowerCase();

    if (translationA < translationB) {
      return -1;
    }
    if (translationA > translationB) {
      return 1;
    }
    return 0;
  }) as PositionType[];
}

export function getPositionTypeText(type: PositionType): string {
  switch (type) {
    case PositionType.AMBULANT:
      return 'Ambulant';
    case PositionType.STATIONARY:
      return 'Stationär';
    case PositionType.CARE:
      return 'Pflege';
    case PositionType.PAY_RATE:
      return 'Lohnersatz';
    case PositionType.OTHER:
      return 'Sonstiges';
    case PositionType.AIDS:
      return 'Hilfsmittel';
    case PositionType.MEDICINE:
      return 'Arznei- & Verbandsmittel';
    case PositionType.TRAVEL_COSTS:
      return 'Fahrtkosten';
    case PositionType.PHYSIOTHERAPY:
      return 'Physiotherapie';
    case PositionType.CREDIT:
      return 'Gutschrift';
  }
}

import { inject, Injectable } from '@angular/core';
import { ExtractedDataStoreService } from '../services/extracted-data/extracted-data-store.service';
import { CtBatch } from '../models/ct-batch-model';
import { TableOverlayStoreService } from '../services/overlay/table-overlay-store.service';
import { BuildSimpleExtractedFieldLocations, ExtractedFields } from '../models/interfaces';
import { BuildSimpleField, getBuildSimpleExtractedFields } from './build-simple-field-processing';
import { BuildSimpleTable, getBuildSimpleExtractedTables } from './build-simple-table-processing';
import { BuildSimpleExtractionResultDto } from './build-simple-model';
import { FieldOverlayStoreService } from '../services/overlay/field-overlay-store.service';

@Injectable({ providedIn: 'root' })
export class BuildSimpleExtractedDataProcessingService {
  private extractedDataStoreService = inject(ExtractedDataStoreService);
  private tableOverlayStoreService = inject(TableOverlayStoreService);
  private fieldOverlayStoreService = inject(FieldOverlayStoreService);

  storeExtractedSuggestions(
    ctBatch: CtBatch,
    extractionDataByDocumentId: Record<string, BuildSimpleExtractionResultDto>,
  ) {
    const extractedBuildSimpleFields = getBuildSimpleExtractedFields(
      ctBatch,
      extractionDataByDocumentId,
    );

    this.storeExtractedFields(extractedBuildSimpleFields);

    const extractedTables = getBuildSimpleExtractedTables(ctBatch, extractionDataByDocumentId);
    this.storeExtractedTables(extractedTables);
  }

  private storeExtractedFields(extractedBuildSimpleFields: BuildSimpleField) {
    const extractedFields = Object.entries(extractedBuildSimpleFields).reduce(
      (acc, [key, value]) => {
        if (value === undefined) return acc;
        acc[key as keyof ExtractedFields] = value.value;
        return acc;
      },
      {} as ExtractedFields,
    );

    const extractedFieldLocations = Object.entries(extractedBuildSimpleFields).reduce(
      (acc, [key, value]) => {
        if (value === undefined) return acc;
        acc[key as keyof ExtractedFields] = {
          location: value.location,
          pageId: value.pageId,
        };
        return acc;
      },
      {} as BuildSimpleExtractedFieldLocations,
    );

    this.fieldOverlayStoreService.setExtractedFieldLocationsFromBSLocation(extractedFieldLocations);
    this.extractedDataStoreService.extractedFields = extractedFields;
  }

  private storeExtractedTables(extractedTables: BuildSimpleTable[]) {
    this.tableOverlayStoreService.deleteAllTables();
    this.tableOverlayStoreService.storeInAppState();
    for (const table of extractedTables) {
      this.tableOverlayStoreService.addNewTable(table.overlayData);
    }

    extractedTables.forEach((extractedTable) => {
      this.extractedDataStoreService.setAuditPositionArray(
        extractedTable.dataRows,
        extractedTable.documentId,
        true,
      );
    });
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WordRect } from '../../models/interfaces';

export interface SelectedWordsData {
  words: string[];
  wordRects: WordRect[];
  pageId: string;
}
@Injectable({
  providedIn: 'root',
})
export class OcrSelectionService {
  selectedWordsData$: Subject<SelectedWordsData> = new Subject<SelectedWordsData>();

  public newSelectedWordsData(words: string[], wordRects: WordRect[], pageId: string) {
    this.selectedWordsData$.next({ words, wordRects, pageId });
  }
}

import { Component, inject, OnInit } from '@angular/core';
import { DocumentPreviewComponent } from '../../domains/preview/document-preview.component';
import { WorkflowService } from '../../services/app-state/workflow.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataCapturingPositionTypeComponent } from '../../domains/data-capturing/data-capturing-position-type/data-capturing-position-type.component';
import { CtBatchStoreService } from '../../services/app-state/ct-batch-store.service';
import { CtDocument } from '../../models/ct-batch-model';

@UntilDestroy()
@Component({
  selector: 'app-position-classification-page',
  standalone: true,
  imports: [DocumentPreviewComponent, DataCapturingPositionTypeComponent],
  templateUrl: './position-classification-page.component.html',
})
export class PositionClassificationPageComponent implements OnInit {
  private workflowService = inject(WorkflowService);
  private ctBatchStoreService = inject(CtBatchStoreService);

  currentDocument!: CtDocument;

  ngOnInit() {
    this.workflowService.currentForderungsaufstellung$
      .pipe(untilDestroyed(this))
      .subscribe((index) => {
        const forderungsaufstellungen = this.ctBatchStoreService.forderungsaufstellungen;

        if (forderungsaufstellungen.length > 0) {
          this.currentDocument = forderungsaufstellungen[index];
        }
      });
  }

  goToNextView(): void {
    this.workflowService.navigateToNextView();
  }
}

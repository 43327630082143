import { Injectable } from '@angular/core';
import {
  AppView,
  AuditPositionWithFieldIndex,
  ExtractedFieldLocations,
  ExtractedFields,
  TableData,
} from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
// should not be used in components, only in services to access localStorage
export class PersistDataService {
  public get ctBatchId(): string | null {
    return this.getLocalStorageValue('ctBatchId', null);
  }

  public set ctBatchId(ctBatchId: string) {
    if (this.ctBatchId === ctBatchId) {
      return;
    }
    // if we load a new work item we want to clear everything else
    // because all the state depends on the ctBatch
    this.reset();
    this.setLocalStorageValue('ctBatchId', ctBatchId);
  }

  public get hasCurrentView(): boolean {
    return this.hasValue('view');
  }

  public get currentView(): AppView | null {
    return this.getLocalStorageValue<AppView>('view');
  }

  public set currentView(view: AppView) {
    this.setLocalStorageValue('view', view);
  }

  public set viewQueue(viewQueue: AppView[]) {
    this.setLocalStorageValue('viewQueue', viewQueue);
  }

  public get viewQueue(): AppView[] | null {
    return this.getLocalStorageValue<AppView[]>('viewQueue');
  }

  private hasValue(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  private setLocalStorageValue(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private getLocalStorageValue<T>(key: string, defaultValue: T | null = null): T | null {
    const value = localStorage.getItem(key);
    if (!value) {
      return defaultValue;
    }
    return JSON.parse(value) as T;
  }

  public set extractedFields(extractedFields: ExtractedFields) {
    this.setLocalStorageValue('extractedFields', extractedFields);
  }

  public get extractedFields(): ExtractedFields | null {
    const fromStorage: ExtractedFields | null = this.getLocalStorageValue('extractedFields');
    if (!fromStorage) return fromStorage;

    fromStorage.ClaimantBirthDate = fromStorage.ClaimantBirthDate
      ? new Date(fromStorage.ClaimantBirthDate)
      : undefined;
    fromStorage.InvoiceDate = fromStorage.InvoiceDate
      ? new Date(fromStorage.InvoiceDate)
      : undefined;
    return fromStorage;
  }

  public set auditPositions(auditPositions: AuditPositionWithFieldIndex[]) {
    this.setLocalStorageValue('auditPositions', auditPositions);
  }

  public get auditPositions(): AuditPositionWithFieldIndex[] | null {
    const fromStorage: AuditPositionWithFieldIndex[] | null =
      this.getLocalStorageValue('auditPositions');

    fromStorage?.map((auditPosition) => {
      auditPosition.from.value = auditPosition.from?.value
        ? new Date(auditPosition.from.value)
        : undefined;
      auditPosition.to.value = auditPosition.to?.value
        ? new Date(auditPosition.to.value)
        : undefined;
    });

    return fromStorage;
  }

  public set tableDataByPageId(tableDataByPageId: Record<string, TableData[]>) {
    this.setLocalStorageValue('tableDataByPageId', tableDataByPageId);
  }

  public get tableDataByPageId(): Record<string, TableData[]> | null {
    return this.getLocalStorageValue('tableDataByPageId');
  }

  public set extractedSingleFieldLocations(extractedSingleFieldLocations: ExtractedFieldLocations) {
    this.setLocalStorageValue('extractedSingleFieldLocations', extractedSingleFieldLocations);
  }

  public get extractedSingleFieldLocations(): ExtractedFieldLocations | null {
    return this.getLocalStorageValue('extractedSingleFieldLocations');
  }

  public set extractedFieldLocations(extractedFieldLocations: ExtractedFieldLocations) {
    this.setLocalStorageValue('extractedFieldLocations', extractedFieldLocations);
  }

  public get extractedFieldLocations(): ExtractedFieldLocations | null {
    return this.getLocalStorageValue('extractedFieldLocations');
  }

  public get currentViewIndex(): number | null {
    return this.getLocalStorageValue<number>('currentViewIndex');
  }

  public set currentViewIndex(currentViewIndex: number) {
    this.setLocalStorageValue('currentViewIndex', currentViewIndex);
  }

  public get currentForderungsaufstellung(): number | null {
    return this.getLocalStorageValue<number>('currentForderungsaufstellung');
  }

  public set currentForderungsaufstellung(currentForderungsaufstellung: number) {
    this.setLocalStorageValue('currentForderungsaufstellung', currentForderungsaufstellung);
  }

  reset() {
    localStorage.clear();
  }
}

import { transformDate } from '../pipes/ocr-date.pipe';
import { BuildSimpleExtractionResultDto, BuildSimplePageLocation } from './build-simple-model';
import { ExtractedFields } from '../models/interfaces';
import { minBy } from '../utils/Array.utils';
import { CtBatch } from '../models/ct-batch-model';

export type BuildSimpleField = Record<
  keyof ExtractedFields,
  { value: Date & string; location: BuildSimplePageLocation; pageId: string }
>;

export const getBuildSimpleExtractedFields = (
  ctBatch: CtBatch,
  extractionDataByDocumentId: Record<string, BuildSimpleExtractionResultDto>,
) => {
  return {
    ClaimantBirthDate: getFieldWithHighestConfidenceFromAllDocuments(
      ctBatch,
      extractionDataByDocumentId,
      'kopfdaten_geburtsdatum',
      (value: string) => transformDate(value).toDate(),
    ),
    InvoiceDate: getFieldWithHighestConfidenceFromAllDocuments(
      ctBatch,
      extractionDataByDocumentId,
      'kopfdaten_forderungsdatum',
      (value: string) => transformDate(value).toDate(),
    ),
    KstSign: getFieldWithHighestConfidenceFromAllDocuments(
      ctBatch,
      extractionDataByDocumentId,
      'kopfdaten_kostentraegerzeichen',
    ),
  } as BuildSimpleField;
};

export const getFieldWithHighestConfidenceFromAllDocuments = <T>(
  ctBatch: CtBatch,
  extractionDataByDocumentId: Record<string, BuildSimpleExtractionResultDto>,
  extractedFieldKey: string,
  transform: (value: string) => T = (x) => x as T,
): undefined | { value: T; location: BuildSimplePageLocation; pageId: string } => {
  const allFieldsForKey = Object.entries(extractionDataByDocumentId).flatMap(
    (extractionData) => extractionData[1].entities[extractedFieldKey] ?? [],
  );

  const highestConfidenceResult = minBy(allFieldsForKey, (field) => field.confidence);
  if (!highestConfidenceResult) {
    return undefined;
  }

  const documentId = Object.entries(extractionDataByDocumentId).find((pair) => {
    return Object.values(pair[1].entities[extractedFieldKey]).find((extractedField) => {
      return JSON.stringify(extractedField) === JSON.stringify(highestConfidenceResult);
    });
  })![0];

  const pageId = ctBatch.documents.find((doc) => {
    return doc.id === documentId;
  })!.pages[highestConfidenceResult.location.page - 1].id;

  return {
    value: transform(highestConfidenceResult.value),
    location: highestConfidenceResult.location,
    pageId: pageId,
  };
};

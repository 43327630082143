<div
  (waResizeObserver)="updateDimensions()"
  class="absolute bottom-0 left-0 right-0 top-0"
  [ngClass]="{
    'cursor-crosshair': isTableCreationToggled && isTableExtractionView,
  }"
></div>

@if (isDimensionSet()) {
  <app-text-selection-overlay
    [selectedWordsRect]="selectedWordRects()"
    [lasso]="boxSelection.currentLasso()"
    [pageId]="page.id"
    [highlight]="fieldHighlight"
    [showTextSelectionOverlay]="!isTableCreationToggled || !isTableExtractionView"
  />

  @if (!isFieldExtractionView) {
    @for (entry of tableCollection; track entry.id) {
      <app-table-overlay
        #tableOverlayComponent
        [page]="page"
        [isTableCreationToggled]="isTableCreationToggled"
        [tableData]="entry"
        [isTableExtractionView]="isTableExtractionView"
        [highlight]="this.tableHighlightByTableId[entry.id]"
        (deleteTable)="deleteTable($event)"
        (storeTableData)="storeTableData($event.pageId, $event.tableData)"
      />
    }

    @if (isTableCreationToggled) {
      <app-table-creation-overlay [pageId]="page.id" [currentLasso]="boxSelection.currentLasso()" />
    }
  }
}

import { inject, Injectable } from '@angular/core';
import { PersistDataService } from '../app-state/persist-data.service';
import {
  BuildSimpleExtractedFieldLocations,
  ExtractedFieldLocationData,
  ExtractedFieldLocations,
  ExtractedFields,
  WordRect,
} from '../../models/interfaces';
import { BuildSimplePageLocation } from '../../build-simple/build-simple-model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FieldOverlayStoreService {
  private persistDataService = inject(PersistDataService);
  private extractedSingleFieldLocations: ExtractedFieldLocations = {};
  private extractedFieldLocations: ExtractedFieldLocations = {};

  fieldHighlight$ = new BehaviorSubject<ExtractedFieldLocationData | undefined>(
    {} as ExtractedFieldLocationData,
  );

  constructor() {
    if (!this.persistDataService.extractedSingleFieldLocations) return;
    this.extractedSingleFieldLocations = this.persistDataService.extractedSingleFieldLocations;

    if (!this.persistDataService.extractedFieldLocations) return;
    this.extractedFieldLocations = this.persistDataService.extractedFieldLocations;
  }

  setExtractedFieldLocationsFromBSLocation(
    extractedFieldLocations: BuildSimpleExtractedFieldLocations,
  ) {
    this.extractedSingleFieldLocations =
      this.transformFieldLocationsToWordRect(extractedFieldLocations);
    this.storeSingleFieldLocationInAppState();
  }

  getLocationOfExtractedField(fieldId: string) {
    if (!this.extractedFieldLocations) return;
    return this.extractedFieldLocations[fieldId];
  }

  getLocationOfExtractedSingleField(fieldId: keyof ExtractedFields) {
    if (!this.extractedSingleFieldLocations) return;
    return this.extractedSingleFieldLocations[fieldId];
  }

  setNewHighlight(field: string, singleFieldValue: boolean) {
    if (singleFieldValue) {
      const extractedSingleFieldLocation = this.getLocationOfExtractedSingleField(
        field as keyof ExtractedFields,
      );

      this.fieldHighlight$.next(extractedSingleFieldLocation);
      return extractedSingleFieldLocation;
    }

    const extractedFieldLocation = this.getLocationOfExtractedField(field);
    this.fieldHighlight$.next(extractedFieldLocation);
    return extractedFieldLocation;
  }

  appendExtractedSingleFieldLocations(
    fieldKey: keyof ExtractedFields,
    wordRects: WordRect[],
    pageId: string,
  ) {
    const oldWordRects: WordRect[] = this.extractedSingleFieldLocations[fieldKey].wordRects;

    this.extractedSingleFieldLocations[fieldKey] = {
      pageId: pageId,
      wordRects: oldWordRects.concat(wordRects),
    };
    this.storeSingleFieldLocationInAppState();
  }

  setExtractedSingleFieldLocations(
    fieldKey: keyof ExtractedFields,
    wordRects: WordRect[],
    pageId: string,
  ) {
    this.extractedSingleFieldLocations[fieldKey] = {
      pageId: pageId,
      wordRects: wordRects,
    };
    this.storeSingleFieldLocationInAppState();
  }

  appendExtractedFieldLocations(fieldKey: string, wordRects: WordRect[], pageId: string) {
    const oldWordRects: WordRect[] = this.extractedFieldLocations[fieldKey].wordRects;

    this.extractedFieldLocations[fieldKey] = {
      pageId: pageId,
      wordRects: oldWordRects.concat(wordRects),
    };
    this.storeFieldLocationInAppState();
  }

  setExtractedFieldLocations(fieldKey: string, wordRects: WordRect[], pageId: string) {
    this.extractedFieldLocations[fieldKey] = {
      pageId: pageId,
      wordRects: wordRects,
    };
    this.storeFieldLocationInAppState();
  }

  deleteFieldLocations(fieldId: string) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this.extractedFieldLocations[fieldId];
    this.storeFieldLocationInAppState();
  }

  private transformFieldLocationsToWordRect(
    extractedFieldLocations: BuildSimpleExtractedFieldLocations,
  ): ExtractedFieldLocations {
    const obj: ExtractedFieldLocations = {};

    (Object.keys(extractedFieldLocations) as (keyof ExtractedFields)[]).forEach((key) => {
      const location = extractedFieldLocations[key]?.location as BuildSimplePageLocation;
      const wordRect: WordRect = {
        x: [location.left, location.right],
        y: [location.upper, location.lower],
      };

      //TODO can bs provide multiple words??
      obj[key] = {
        wordRects: [wordRect],
        pageId: extractedFieldLocations[key]!.pageId,
      };
    });
    return obj;
  }

  storeSingleFieldLocationInAppState() {
    this.persistDataService.extractedSingleFieldLocations = this.extractedSingleFieldLocations;
  }

  storeFieldLocationInAppState() {
    this.persistDataService.extractedFieldLocations = this.extractedFieldLocations;
  }
}

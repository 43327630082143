import { inject, Injectable } from '@angular/core';
import { PersistDataService } from '../app-state/persist-data.service';
import { TableData, WordRect } from '../../models/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableOverlayStoreService {
  private persistDataService = inject(PersistDataService);
  private tableDataByPageId: Record<string, TableData[]> = {};
  tableHighlightByTableId$ = new BehaviorSubject<Record<string, WordRect | undefined>>({});

  constructor() {
    if (!this.persistDataService.tableDataByPageId) return;
    this.tableDataByPageId = this.persistDataService.tableDataByPageId;
  }

  setNewHighlight(tableId: string, highlight: WordRect | undefined): void {
    const tableHighlightByTableId: Record<string, WordRect | undefined> = {};
    tableHighlightByTableId[tableId] = highlight;

    this.tableHighlightByTableId$.next(tableHighlightByTableId);
  }

  addNewTable(tableData: TableData): TableData[] {
    const pageId = tableData.pageId;
    this.tableDataByPageId[pageId] = [...this.getTablesOnPage(pageId), tableData];
    this.storeInAppState();

    return this.getTablesOnPage(pageId);
  }

  getTablesOnPage(pageId: string): TableData[] {
    return this.tableDataByPageId[pageId] ?? [];
  }

  getAllTables(): TableData[] {
    return Object.values(this.tableDataByPageId).flat();
  }

  storeTableOverlayAtPage(pageId: string, tableData: TableData) {
    const tablesOnPage = this.getTablesOnPage(pageId);
    const dataIndex = tablesOnPage.findIndex((table) => table.id === tableData.id);

    if (dataIndex === -1) return;

    tablesOnPage[dataIndex] = tableData;

    const collectionOnPage = this.tableDataByPageId;
    collectionOnPage[pageId] = tablesOnPage;

    this.tableDataByPageId = structuredClone(collectionOnPage);
    this.storeInAppState();
  }

  deleteTableOverlayAtPage(pageId: string, tableId: string): TableData[] {
    const dataIndex = this.tableDataByPageId[pageId].findIndex((table) => table.id === tableId);
    if (dataIndex === -1) return this.getTablesOnPage(pageId);

    this.tableDataByPageId[pageId].splice(dataIndex, 1);
    this.storeInAppState();

    return this.getTablesOnPage(pageId);
  }

  deleteUnconfirmedTables() {
    for (const pageId in this.tableDataByPageId) {
      this.tableDataByPageId[pageId] = this.tableDataByPageId[pageId].filter(
        (tableData) => tableData.confirmed,
      );
    }

    this.storeInAppState();
  }

  deleteAllTables() {
    this.tableDataByPageId = {};
  }

  storeInAppState() {
    this.persistDataService.tableDataByPageId = this.tableDataByPageId;
  }
}

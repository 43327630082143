import { Component, ElementRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { CtBatchStoreService } from '../../services/app-state/ct-batch-store.service';
import { OcrSelectionService } from '../../services/ocr/ocr-selection.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { DataCapturingSingleFieldsComponent } from '../../domains/data-capturing/data-capturing-single-fields-component/data-capturing-single-fields.component';
import { DocumentPreviewComponent } from '../../domains/preview/document-preview.component';
import { FieldType } from '../../models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { sanitizeDateInput } from '../../utils/SanitizingInput.utils';
import { transformDate } from '../../pipes/ocr-date.pipe';
import { FieldOverlayStoreService } from '../../services/overlay/field-overlay-store.service';

@UntilDestroy()
@Component({
  selector: 'app-single-field-extraction-page',
  standalone: true,
  imports: [DocumentPreviewComponent, DataCapturingSingleFieldsComponent],
  templateUrl: './single-field-extraction-page.component.html',
})
export class SingleFieldExtractionPageComponent implements OnInit {
  protected ctBatchStoreService = inject(CtBatchStoreService);
  protected ocrSelectionService = inject(OcrSelectionService);
  protected confirmService = inject(ConfirmService);
  private fieldOverlayStoreService = inject(FieldOverlayStoreService);

  @ViewChild('formElementContainer') formElementContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('documentFieldsComponent')
  documentFieldsComponent!: DataCapturingSingleFieldsComponent;

  private isCtrlPressed = false;

  ngOnInit() {
    this.ocrSelectionService.selectedWordsData$
      .pipe(untilDestroyed(this))
      .subscribe((selectedWordsData) => {
        const joinedString = selectedWordsData.words.join(' ');
        const processedValue = this.processValue(
          joinedString,
          this.documentFieldsComponent.currentField.type,
        );

        if (!processedValue) {
          return;
        }

        if (this.isCtrlPressed) {
          this.documentFieldsComponent.augmentCurrentField(processedValue);

          this.fieldOverlayStoreService.appendExtractedSingleFieldLocations(
            this.documentFieldsComponent.currentField.key,
            selectedWordsData.wordRects,
            selectedWordsData.pageId,
          );
        } else {
          this.documentFieldsComponent.patchCurrentField(processedValue);

          this.fieldOverlayStoreService.setExtractedSingleFieldLocations(
            this.documentFieldsComponent.currentField.key,
            selectedWordsData.wordRects,
            selectedWordsData.pageId,
          );
        }

        this.documentFieldsComponent.saveExtractedFields();

        this.fieldOverlayStoreService.setNewHighlight(
          this.documentFieldsComponent.currentField.key,
          true,
        );
      });
  }

  private processValue(rawValue: string, type: FieldType) {
    if (type === 'date') {
      const date = transformDate(sanitizeDateInput(rawValue));

      if (!date.isValid()) {
        this.confirmService.snackError(
          `Der Wert "${sanitizeDateInput(rawValue)}" ist kein gültiges Datum`,
        );
        return undefined;
      } else {
        return date.toDate();
      }
    }

    return rawValue;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.isCtrlPressed = true;
    }

    if (event.code === 'Enter') {
      console.log(123);
      this.documentFieldsComponent.focusNextField();
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.isCtrlPressed = false;
    }
  }
}

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { OverlayRectComponent } from '../../overlay-rect/overlay-rect.component';
import { ExtractedFieldLocationData, WordRect } from '../../../../models/interfaces';
import { RectDisplayStylePipe } from '../../overlay-rect/rect-display-style.pipe';
import { ScaleOverlayRectPipe } from '../../overlay-rect/scale-overlay-rect.pipe';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-text-selection-overlay',
  standalone: true,
  imports: [OverlayRectComponent, RectDisplayStylePipe, ScaleOverlayRectPipe, NgStyle],
  templateUrl: './text-selection-overlay.component.html',
})
export class TextSelectionOverlayComponent implements OnChanges {
  @Input({ required: true }) selectedWordsRect: WordRect[] = [];
  @Input({ required: true }) lasso: WordRect | undefined;
  @Input({ required: true }) pageId!: string;
  @Input({ required: true }) highlight!: ExtractedFieldLocationData | undefined;
  @Input({ required: true }) showTextSelectionOverlay!: boolean;

  @ViewChildren('fieldHighlight') fieldHighlight!: QueryList<ElementRef<HTMLDivElement>>;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['highlight']) return;
    if (!(changes['highlight'].currentValue as ExtractedFieldLocationData)?.wordRects) return;

    setTimeout(() => {
      this.fieldHighlight
        .get(0)
        ?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TableAuditPositionRow } from '../../../../../models/interfaces';

@Component({
  selector: 'app-edit-table-layout',
  standalone: true,
  imports: [NgStyle, MatIcon, MatTooltip],
  templateUrl: './edit-table-layout.component.html',
  styleUrl: './edit-table-layout.component.scss',
})
export class EditTableLayoutComponent {
  @Input({ required: true }) topAndBottomAddLineControls!: boolean;
  @Input({ required: true }) rowIndex!: number;
  @Input({ required: true }) row!: TableAuditPositionRow;

  @Output() deleteRowAtIndex = new EventEmitter<number>();
  @Output() addRowEmit = new EventEmitter<{ index: number; row: TableAuditPositionRow }>();
}

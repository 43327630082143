import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { WordRect } from '../../models/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableDetectionApiService extends ApiService {
  constructor() {
    super('tableDetectionUrl');
  }

  /**
   * Returns an array of columns. Each column is an array of indices
   * from the input array of elements belonging together.
   * The columns are ordered from left to right.
   *
   * @param wordRects all words from the ocr representing a table
   */
  detectTable(wordRects: WordRect[]): Observable<number[][]> {
    // TODO: 0.01 is an 'sometimes-okay'-threshold, but we should let the user influence that somehow
    return this.post('detect-table', { distanceThreshold: 0.01, wordRects });
  }
}

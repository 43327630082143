@if (showTextSelectionOverlay) {
  @for (rect of this.selectedWordsRect; track $index) {
    <app-overlay-rect styleClasses="bg-primary opacity-50" [rect]="rect" [pageId]="pageId" />
  }
  @if (lasso) {
    <app-overlay-rect styleClasses="bg-blue-400 opacity-50" [rect]="lasso" [pageId]="pageId" />
  }
}

@if (highlight) {
  @for (wordRect of highlight.wordRects; track $index) {
    <div
      #fieldHighlight
      class="absolute bg-primary opacity-30"
      [ngStyle]="wordRect | scaleOverlayRect: highlight.pageId | rectDisplayStyle"
    ></div>
  }
}

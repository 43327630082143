import { Component, inject, OnInit } from '@angular/core';
import { ButtonStateService } from '../../../services/app-state/button-state.service';
import { MatTooltip } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonState } from '../../../models/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [MatTooltip],
})
export class FooterComponent implements OnInit {
  private buttonStateService = inject(ButtonStateService);

  nextButtonState: ButtonState = {
    label: '',
    tooltip: '',
    isActive: () => {
      return true;
    },
    action: () => undefined,
  };

  prevButtonState: ButtonState = {
    label: '',
    tooltip: '',
    isActive: () => {
      return true;
    },
    action: () => undefined,
  };

  ngOnInit() {
    this.buttonStateService.nextButtonState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.nextButtonState = state;
    });

    this.buttonStateService.prevButtonState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.prevButtonState = state;
    });
  }
}

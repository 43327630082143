<div class="flex h-full w-full flex-col rounded-xl bg-white drop-shadow-card">
  <div
    class="flex h-fit w-full flex-row items-center gap-x-3 border-b border-b-theme-blue-gray p-1 pl-4"
  >
    <img
      src="../../../../assets/icons/document-class/FORDERUNGSAUFSTELLUNG.svg"
      loading="lazy"
      alt=""
      class="h-7"
    />
    <span class="flex items-center gap-4 p-1 text-lg font-bold">
      <span class="py-3">Forderungen</span>
    </span>
  </div>
  <div class="h-full overflow-auto">
    <app-table
      [tableHeader]="positionClassificationHeaders"
      [tableRows]="tableRows"
      [inputTypes]="inputTypes"
      [consideredPropertyKeys]="['description', 'amount', 'positionType']"
      [isTableLayoutEditable]="false"
      [isTableExtractionView]="workflowService.getCurrentView() === AppView.TABLE_EXTRACTION"
      [isPositionClassificationView]="
        workflowService.getCurrentView() === AppView.POSITION_CLASSIFICATION
      "
      (setRowData)="setRowDataAtIndex($event.event, $event.header, $event.rowIndex)"
      (dropDownValueSet)="
        setDropdownValueAtIndex($event.positionType, $event.header, $event.rowIndex)
      "
      #tableComponent
    />
  </div>
</div>

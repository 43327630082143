import {
  Component,
  computed,
  ElementRef,
  inject,
  Input,
  OnInit,
  QueryList,
  signal,
  Signal,
  ViewChildren,
} from '@angular/core';
import {
  ExtractedFieldLocationData,
  LineOrientation,
  pointRect,
  TableData,
  WordRect,
} from '../../models/interfaces';
import { NgClass } from '@angular/common';
import { LassoSelectionDirective } from '../../directives/lasso-selection.directive';
import { TableOverlayComponent } from './table-creation/table-overlay/table-overlay.component';
import { ConfirmService } from '@l21s-ecnps/gui-commons';

import { OverlayRectComponent } from './overlay-rect/overlay-rect.component';
import { TableOverlayStoreService } from '../../services/overlay/table-overlay-store.service';
import { TextSelectionOverlayComponent } from './text-selection/text-selection-overlay/text-selection-overlay.component';
import { TableCreationOverlayComponent } from './table-creation/table-creation-overlay/table-creation-overlay.component';
import { OverlayDimensionService } from '../../services/overlay/overlay-dimension.service';
import { OcrService } from '../../services/ocr/ocr.service';
import { OcrSelectionService } from '../../services/ocr/ocr-selection.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CtPage } from '../../models/ct-batch-model';
import { WaResizeObserver } from '@ng-web-apis/resize-observer';
import { FieldOverlayStoreService } from '../../services/overlay/field-overlay-store.service';

@UntilDestroy()
@Component({
  selector: 'app-ocr-overlay',
  standalone: true,
  imports: [
    TableOverlayComponent,
    WaResizeObserver,
    NgClass,
    OverlayRectComponent,
    TextSelectionOverlayComponent,
    TableCreationOverlayComponent,
  ],
  templateUrl: './ocr-overlay.component.html',
})
export class OcrOverlayComponent implements OnInit {
  protected boxSelection: LassoSelectionDirective = inject(LassoSelectionDirective);
  private confirmService = inject(ConfirmService); //

  private tableOverlayStoreService = inject(TableOverlayStoreService); //
  private fieldOverlayStoreService = inject(FieldOverlayStoreService);
  private overlayDimensionService = inject(OverlayDimensionService); //

  private ocrService = inject(OcrService); //
  private ocrSelectionService = inject(OcrSelectionService); //

  @Input() isTableCreationToggled!: boolean;
  @Input() page!: CtPage;
  @Input() isFieldExtractionView!: boolean;
  @Input() isTableExtractionView!: boolean;

  @ViewChildren('tableOverlayComponent') tableOverlayComponent!: QueryList<TableOverlayComponent>;

  protected lastReleasedLasso: WordRect | undefined;
  protected tableCollection: TableData[] = [];
  private hostElement: HTMLElement;

  protected selectedWordRects: Signal<WordRect[]> = signal([]);
  protected tableHighlightByTableId!: Record<string, WordRect | undefined>;
  protected fieldHighlight: ExtractedFieldLocationData | undefined = undefined;

  constructor(elementRef: ElementRef<HTMLElement>) {
    this.hostElement = elementRef.nativeElement;

    this.boxSelection.lastReleasedLasso$.pipe(untilDestroyed(this)).subscribe((lasso) => {
      if (this.isFieldExtractionView || !this.isTableCreationToggled) return;
      this.lastReleasedLasso = lasso;
      if (!this.canCreateTable(lasso)) return;

      const newTable: TableData = {
        lasso: lasso,
        confirmed: false,
        layoutDefined: false,
        lines: {
          vertical: [
            { offset: lasso.x[0], orientation: LineOrientation.VERTICAL, isBoundary: true },
            { offset: lasso.x[1], orientation: LineOrientation.VERTICAL, isBoundary: true },
          ],
          horizontal: [
            {
              offset: lasso.y[0],
              orientation: LineOrientation.HORIZONTAL,
              isBoundary: true,
            },
            {
              offset: lasso.y[1],
              orientation: LineOrientation.HORIZONTAL,
              isBoundary: true,
            },
          ],
        },
        columnHeaders: [],
        id: crypto.randomUUID(),
        pageId: this.page.id,
      };

      this.tableCollection = structuredClone(this.tableOverlayStoreService.addNewTable(newTable));
    });

    this.tableOverlayStoreService.tableHighlightByTableId$
      .pipe(untilDestroyed(this))
      .subscribe((tableHighlightByTableId) => {
        this.tableHighlightByTableId = structuredClone(tableHighlightByTableId);
      });

    this.fieldOverlayStoreService.fieldHighlight$
      .pipe(untilDestroyed(this))
      .subscribe((fieldHighlight) => {
        if (!fieldHighlight?.pageId) {
          this.fieldHighlight = undefined;
          return;
        }
        if (fieldHighlight.pageId !== this.page?.id) {
          this.fieldHighlight = undefined;
          return;
        }

        this.fieldHighlight = fieldHighlight;
        this.tableOverlayStoreService.tableHighlightByTableId$.next({});
      });
  }

  ngOnInit(): void {
    this.tableCollection = structuredClone(
      this.tableOverlayStoreService.getTablesOnPage(this.page.id),
    );

    this.selectedWordRects = computed(() => {
      const lasso =
        this.boxSelection.currentLasso() ?? pointRect(this.boxSelection.currentMousePosition());

      return this.ocrService.getWordsInRect(this.page, lasso);
    });

    this.initReleasedLassoHandling();
  }

  protected deleteTable(id: string): void {
    this.tableCollection = structuredClone(
      this.tableOverlayStoreService.deleteTableOverlayAtPage(this.page.id, id),
    );
  }

  protected updateDimensions(): void {
    this.fieldHighlight = structuredClone(this.fieldHighlight);
    this.tableOverlayComponent.forEach((tableOverlayComponent) => {
      tableOverlayComponent.highlight = structuredClone(tableOverlayComponent.highlight);
    });

    this.overlayDimensionService.setDimension(this.page.id, {
      width: this.hostElement.offsetWidth,
      height: this.hostElement.offsetHeight,
    });
  }

  private canCreateTable(lasso: WordRect): boolean {
    const MIN_SIZE = 0.1;
    const size = lasso.x[1] - lasso.x[0] + lasso.y[1] - lasso.y[0];
    const areAllTablesConfirmed = !this.tableCollection.some((tableData) => {
      return !tableData.confirmed;
    });

    const isRectSizeEnough = size >= MIN_SIZE;
    if (!areAllTablesConfirmed) {
      this.confirmService.snackError(
        'Die aktuelle Tabelle muss bestätigt werden bevor die nächste erstellt werden kann',
      );
    } else if (!isRectSizeEnough) {
      this.confirmService.snackError('Ein größerer Bereich muss ausgewählt werden');
    }

    return size >= MIN_SIZE && areAllTablesConfirmed;
  }

  getTableCollection(): TableData[] {
    return this.tableCollection;
  }

  isDimensionSet() {
    return Boolean(this.overlayDimensionService.dimensionsByPage$.value[this.page.id]);
  }

  private initReleasedLassoHandling() {
    this.boxSelection.lastReleasedLasso$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.selectedWordRects().length === 0) return;

      const selectedWords = this.selectedWordRects().map((rect) =>
        this.ocrService.getWord(this.page, rect),
      );
      this.ocrSelectionService.newSelectedWordsData(
        selectedWords,
        this.selectedWordRects(),
        this.page.id,
      );
    });
  }

  storeTableData(pageId: string, tableData: TableData): void {
    this.tableOverlayStoreService.storeTableOverlayAtPage(pageId, tableData);
  }
}

<div
  class="row group relative flex flex-row items-center justify-between border-t border-t-theme-gray-400 py-2 hover:bg-theme-blue-gray"
>
  @for (header of tableHeader; track header; let headerIndex = $index) {
    @if (inputTypes[header] === InputType.DROPDOWN) {
      <div [ngClass]="getWidthForInput(tableHeaderConstants.type)">
        <app-dropdown
          [dropdownOptions]="positionTypes"
          [header]="header"
          [isOpened]="currentFieldIndex === rowIndex * tableHeader.length + headerIndex"
          [selectedValue]="row.positionType.value"
          (valueSet)="dropDownValueSet.emit({ positionType: $event, header, rowIndex })"
          (setCurrentFieldIndex)="
            $event
              ? newCurrentFieldIndex.emit(rowIndex * tableHeader.length + headerIndex)
              : newCurrentFieldIndex.emit(undefined)
          "
          class="table-input"
        />
      </div>
    } @else if (inputTypes[header] === InputType.INPUT) {
      <div [ngClass]="getWidthForInput(header)">
        <input
          class="table-input box-border block h-full w-full rounded border p-1 text-left ring-1"
          [type]="getInputType(header)"
          [ngClass]="getInputClasses(row, rowIndex, currentFieldIndex, headerIndex, header)"
          (focusout)="focusOutInput.emit({ event: $event, header, rowIndex })"
          (click)="newCurrentFieldIndex.emit(rowIndex * tableHeader.length + headerIndex)"
          [attr.data-row]="rowIndex"
          [attr.data-header]="header"
          [value]="getCellData(row, header)"
          [matTooltip]="getTooltipData(row, header) ?? ''"
          matTooltipClass="basic-tooltip"
          matTooltipPosition="before"
        />
      </div>
    }
  }
  @if (isTableLayoutEditable) {
    <app-edit-table-layout
      [topAndBottomAddLineControls]="topAndBottomAddLineControls"
      [row]="row"
      [rowIndex]="rowIndex"
      (deleteRowAtIndex)="deleteRowAtIndex.emit($event)"
      (addRowEmit)="onAddRow($event.index, $event.row)"
    />
  }
</div>

import { tableHeaderConstants } from '../models/interfaces';

export function getWidthForInput(header: string) {
  switch (header) {
    case tableHeaderConstants.description:
      return 'w-5/12';
    case tableHeaderConstants.from:
    case tableHeaderConstants.to:
      return 'w-2/12';
    case tableHeaderConstants.amount:
      return 'w-2/12';
    case tableHeaderConstants.type:
      return 'w-4/12';
    default:
      return 'w-[24px]';
  }
}

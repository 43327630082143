<div class="flex h-full flex-col rounded-xl bg-white drop-shadow-card">
  <div class="flex h-full min-h-0 max-w-full rounded-lg bg-theme-gray-900">
    <div class="flex min-w-0 grow flex-row rounded-lg">
      <app-preview-tool-bar
        [currentPageIndex]="currentPageIndex"
        [currentPage]="allPages[currentPageIndex]"
        [totalPageCount]="allPages.length"
        [isTableCreationToggled]="isTableCreationToggled"
        [isTableView]="workflowService.isTableExtractionView()"
        [isFieldExtractionView]="workflowService.isFieldExtractionView()"
        [fitType]="fitType()"
        [areTablesOnCurrentPage]="areTablesOnCurrentPage()"
        [doUnconfirmedTablesExist]="doUnconfirmedTablesExist()"
        (toggleFitType)="onToggleFit()"
        (zoomChange)="onChangeZoom($event)"
        (toggleTableCreation)="onToggleTableCreation()"
      />
      <!--img container-->
      <div
        class="items-safe-center flex h-full w-full flex-col gap-y-16 overflow-auto border-theme-gray-900 p-4"
        id="img-container"
        waIntersectionObserver
        waIntersectionThreshold="0.55"
        #pageImageContainer
      >
        @for (document of sortedDocuments; track document.id) {
          @for (page of document.pages; track page.id) {
            <div
              class="relative shrink-0"
              [style.aspect-ratio]="getAspectRatio()"
              [ngStyle]="getPageContainerStyle()"
            >
              <app-document-page-preview
                class="w-full"
                [document]="document"
                [page]="page"
                [fitType]="fitType()"
                [zoom]="currentZoom"
                (pageIntersection)="onPageImageScrolledIntoView($event.pageId)"
              />
              <app-ocr-overlay
                (contextmenu)="$event.preventDefault()"
                class="absolute left-0 top-0 h-full w-full"
                appLassoSelection
                [page]="page"
                [isTableCreationToggled]="isTableCreationToggled"
                [isFieldExtractionView]="workflowService.isFieldExtractionView()"
                [isTableExtractionView]="workflowService.isTableExtractionView()"
                #ocrOverlayComponent
              />
            </div>
          }
        }
      </div>
    </div>
    <div
      class="relative"
      (mouseenter)="isPreviewListHovered = true"
      (mouseleave)="isPreviewListHovered = false"
    >
      <app-image-list-preview
        [isPreviewListExpandedOrHovered]="isPreviewListExpanded || isPreviewListHovered"
        [documents]="sortedDocuments"
        [currentPageIndex]="currentPageIndex"
        [selectedDocumentId]="selectedDocumentId"
        [isFieldExtractionView]="workflowService.isFieldExtractionView()"
        (scrollToIndex)="scrollToPage($event)"
      />
      <button
        (click)="isPreviewListExpanded = !isPreviewListExpanded"
        [ngClass]="isPreviewListHovered ? 'opacity-100' : isPreviewListExpanded ? 'opacity-0' : ''"
        class="group absolute -left-3 top-4 z-10 flex items-center justify-center rounded-full border border-zinc-500 bg-zinc-600 text-white transition-all duration-500 hover:bg-zinc-500 hover:opacity-100"
      >
        @if (isPreviewListExpanded) {
          <mat-icon>keyboard_arrow_left</mat-icon>
        } @else {
          <mat-icon>keyboard_arrow_right</mat-icon>
        }
      </button>
    </div>
  </div>
</div>

<div class="flex h-9 w-full flex-row justify-end bg-white text-xs font-bold text-theme-gray-900">
  @if (prevButtonState.isActive()) {
    <button
      class="px-4 uppercase"
      (click)="prevButtonState.action()"
      [disabled]="!prevButtonState.isActive()"
    >
      {{ prevButtonState.label }}
    </button>
  }
  <button
    [matTooltip]="!nextButtonState.isActive() ? nextButtonState.tooltip : ''"
    matTooltipClass="basic-tooltip"
    [matTooltipPosition]="'above'"
    class="next-button bg-primary px-4 uppercase"
    [disabled]="!nextButtonState.isActive()"
    (click)="nextButtonState.action()"
  >
    {{ nextButtonState.label }}
  </button>
</div>

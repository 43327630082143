<button
  class="relative flex h-full w-fit items-center justify-center rounded-full bg-primary p-1 focus:outline-0"
  (click)="setCurrentFieldIndex.emit(true)"
  #menuButton
>
  @if (!selectedValue) {
    <mat-icon [ngStyle]="{ color: 'white' }">add</mat-icon>
  } @else {
    <span class="block w-full overflow-hidden px-3 text-white">
      {{
        getPositionTypeText(selectedValue) === undefined
          ? placeholder
          : getPositionTypeLabel(selectedValue)
      }}
    </span>
  }

  @if (isOpened) {
    <div
      class="absolute -bottom-1 left-0 z-[100] flex min-w-full translate-y-full flex-col items-start rounded bg-white px-1 py-3 shadow-[0px_2px_4px_rgba(0,0,0,0.33)]"
    >
      @if (header) {
        <span class="mb-2 px-3 font-bold">{{ header }}</span>
      }
      @for (option of dropdownOptions; track $index) {
        <button
          class="word-bre w-full text-nowrap rounded px-3 text-left hover:bg-primary focus:bg-primary focus:outline-none"
          (click)="selectValue(option); $event.stopPropagation()"
          #optionButton
        >
          {{ getPositionTypeLabel(option) }}
        </button>
      }
    </div>
  }
</button>

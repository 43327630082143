<div class="flex h-fit w-full flex-col rounded-xl bg-white drop-shadow-card">
  <div class="h-fit w-full border-b border-b-theme-blue-gray p-4">
    <span class="text-lg font-bold">Vorgang</span>
  </div>
  <div class="p-4">
    <form [formGroup]="formGroup" class="gap-y-4">
      <div class="flex flex-wrap gap-4" #formElementContainer>
        @for (fieldModel of fieldModels; track fieldModel.key; let i = $index) {
          <div class="flex w-full flex-col gap-y-2">
            <span class="text-base font-bold">{{ fieldModel.label }}</span>
            @if (fieldModel.type === "string") {
              <input
                type="text"
                [formControlName]="fieldModel.key"
                class="h-[42px] rounded border p-3 ring-1"
                (click)="onFieldInputClicked(i)"
                [ngClass]="
                  getInputClasses(
                    getControl(fieldModel.key).value,
                    i,
                    shouldDisplayFieldError(fieldModel.key)
                  )
                "
                (focusout)="saveExtractedFields()"
              />
            } @else {
              <p-calendar
                dateFormat="dd.mm.yy"
                class="h-[42px] rounded border ring-1"
                [ngClass]="
                  getInputClasses(
                    getControl(fieldModel.key).value,
                    i,
                    shouldDisplayFieldError(fieldModel.key) ||
                      formGroup.hasError('birthDateAfterInvoiceDate')
                  )
                "
                [formControlName]="fieldModel.key"
                [showIcon]="true"
                (onSelect)="focusNextField()"
                (click)="onFieldInputClicked(i)"
                (focusout)="saveExtractedFields()"
              >
              </p-calendar>
            }
            @if (
              formGroup.hasError("birthDateAfterInvoiceDate") &&
              fieldModel.key === "ClaimantBirthDate"
            ) {
              <span class="text-sm text-red-700">{{ birthDateAfterInvoiceDateMessage }}</span>
            }
            @if (shouldDisplayFieldError(fieldModel.key)) {
              <span class="text-sm text-red-700">{{
                getControl(fieldModel.key).hasError("required")
                  ? requiredErrorMessage
                  : "Unbekannter Fehler"
              }}</span>
            }
          </div>
        }
      </div>
    </form>
  </div>
</div>

import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { TableExtractionToolHintsComponent } from './table-extraction-tool-hints/table-extraction-tool-hints.component';
import { ToolbarButtonComponent } from './toolbar-button/toolbar-button.component';
import { FitType } from '../../../../models/interfaces';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltip } from '@angular/material/tooltip';
import { CtPage } from '../../../../models/ct-batch-model';
import { ConfirmService } from '@l21s-ecnps/gui-commons';

@Component({
  selector: 'app-preview-tool-bar',
  standalone: true,
  imports: [
    NgClass,
    TableExtractionToolHintsComponent,
    ToolbarButtonComponent,
    TooltipModule,
    MatTooltip,
  ],
  templateUrl: './preview-tool-bar.component.html',
})
export class PreviewToolBarComponent {
  @Input({ required: true }) currentPageIndex!: number;
  @Input({ required: true }) totalPageCount!: number;
  @Input({ required: true }) currentPage!: CtPage;
  @Input({ required: true }) isTableCreationToggled!: boolean;
  @Input({ required: true }) fitType!: FitType;
  @Input({ required: true }) isTableView!: boolean;
  @Input({ required: true }) isFieldExtractionView!: boolean;
  @Input({ required: true }) areTablesOnCurrentPage!: boolean;
  @Input({ required: true }) doUnconfirmedTablesExist!: boolean;

  @Output() protected toggleFitType = new EventEmitter<void>();
  @Output() protected zoomChange = new EventEmitter<number>();
  @Output() protected rotate = new EventEmitter<number>();
  @Output() protected toggleTableCreation = new EventEmitter<boolean>();

  private confirmService = inject(ConfirmService);

  protected onRotateClicked() {
    this.confirmService.snackError(
      `Funktion zurzeit nicht verfügbar. Bitte Seiten-ID an Julian weitergeben: ${this.currentPage.id}`,
    );
  }

  readonly zoomStep: number = 0.3;

  protected readonly FitType = FitType;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LocalizedDatePipe } from '../../../pipes/localized-date.pipe';
import { MatIcon } from '@angular/material/icon';
import { SharedModule } from 'primeng/api';
import { NgClass, NgStyle } from '@angular/common';
import { DialogComponent } from '../dialog/dialog.component';
import { AuditPositionWithFieldIndex, ExtractedFields } from '../../../models/interfaces';

@Component({
  selector: 'app-completion-dialog',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    LocalizedDatePipe,
    MatIcon,
    SharedModule,
    NgStyle,
    DialogComponent,
    NgClass,
  ],
  templateUrl: './completion-dialog.component.html',
})
export class CompletionDialogComponent {
  @Input({ required: true }) extractedFieldsObject!: ExtractedFields;
  @Input({ required: true }) auditPositionArray!: AuditPositionWithFieldIndex[];

  @Output() clickedFieldEdit = new EventEmitter<void>();
  @Output() acceptedDialog = new EventEmitter<void>();
  @Output() clickedForderungenEdit = new EventEmitter<void>();
}

export function getDefaultInputClasses(value: unknown, isFocused: boolean, displayError: boolean) {
  let classes = '';

  // State 1: Feld ist leer + nicht fokussiert + n.fehlerhaft    => gelbe Border + transparenter Ring
  // State 3: Feld ist leer + fokussiert + n.fehlerhaft          => gelbe Border + gelber Ring
  // State 2: Feld ist leer + nicht fokussiert + fehlerhaft      => rote Border + transparenter Ring
  // State 4: Feld ist leer + fokussiert + fehlerhaft            => rote Border + roter Ring
  // State 5: Feld ist gefüllt + nicht fokussiert + n.fehlerhaft => graue Border + transparenter Ring
  // State 7: Feld ist gefüllt + fokussiert + n.fehlerhaft       => graue Border + grauer Ring
  // State 6: Feld ist gefüllt + nicht fokussiert + fehlerhaft   => rote Border  + transparenter Ring
  // State 8: Feld ist gefüllt + fokussiert + fehlerhaft         => rote Border + roter Ring

  if (displayError) {
    return isFocused ? 'border-red-500 ring-red-500' : 'border-red-500 ring-transparent';
  }

  if (value) {
    classes += 'border-gray-400';
    if (isFocused) {
      classes += ' ring-gray-400';
    } else {
      classes += ' ring-transparent';
    }
  }

  if (!value) {
    classes += 'border-primary';
    if (isFocused) {
      classes += ' ring-primary';
    } else {
      classes += ' ring-transparent';
    }
  }

  return classes;
}

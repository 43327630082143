<button
  (click)="deleteRowAtIndex.emit(rowIndex)"
  matTooltip="Zeile löschen"
  matTooltipClass="basic-tooltip"
  matTooltipPosition="before"
  class="invisible flex h-[26px] w-[26px] items-center justify-center rounded-md bg-theme-danger group-hover:visible"
>
  <mat-icon [ngStyle]="{ color: 'white' }">close</mat-icon>
</button>
<div
  class="addRowOverlay group/add-button -top-2"
  (dblclick)="addRowEmit.emit({ index: rowIndex, row: row })"
>
  <div class="addRowLine"></div>
  <button
    class="addRowButton"
    matTooltip="Zeile einfügen"
    matTooltipClass="basic-tooltip"
    (click)="addRowEmit.emit({ index: rowIndex, row: row })"
  >
    <mat-icon [ngStyle]="{ color: 'white' }">add</mat-icon>
  </button>
</div>
@if (topAndBottomAddLineControls) {
  <div
    class="addRowOverlay group/add-button -bottom-2"
    (dblclick)="addRowEmit.emit({ index: rowIndex + 1, row: row })"
  >
    <div class="addRowLine"></div>
    <button
      class="addRowButton"
      matTooltip="Zeile einfügen"
      matTooltipClass="basic-tooltip"
      (click)="addRowEmit.emit({ index: rowIndex + 1, row: row })"
    >
      <mat-icon [ngStyle]="{ color: 'white' }">add</mat-icon>
    </button>
  </div>
}

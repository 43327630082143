import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgStyle } from '@angular/common';
import { PositionType } from '../../../../../models/interfaces';
import { getPositionTypeText } from '../../../../../utils/utils';
import { getWidthForInput } from '../../../../../utils/DataCapturingTableWidth.utils';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [MatIcon, NgStyle],
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnChanges {
  @Input({ required: true }) dropdownOptions: PositionType[] = [];
  @Input() placeholder!: string;
  @Input() header!: string;
  @Input() isOpened = false;
  @Input() selectedValue!: PositionType | undefined;
  @Input() isSelected!: boolean;
  @Input() currentFieldIndex!: number | undefined;

  @Output() valueSet = new EventEmitter<PositionType>();
  @Output() setCurrentFieldIndex = new EventEmitter<boolean>();

  @ViewChild('menuButton') menuButton!: ElementRef<HTMLButtonElement>;
  @ViewChildren('optionButton') optionButtons!: QueryList<ElementRef<HTMLButtonElement>>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpened']?.currentValue && this.menuButton) {
      this.menuButton.nativeElement.focus();
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    event.stopPropagation();

    if (event.key !== 'Escape') {
      this.selectPositionTypeWithKey(event.key);
      return;
    }

    const target = event.target as HTMLButtonElement;
    target.blur();
    this.setCurrentFieldIndex.emit(false);
  }

  @HostListener('window:click', ['$event']) onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (
      !this.isOpened ||
      this.menuButton.nativeElement.contains(target) ||
      target.classList.contains('next-button')
    )
      return;
    this.setCurrentFieldIndex.emit(false);
  }

  selectPositionTypeWithKey(key: string): void {
    const matchingButtons = this.optionButtons.filter((buttonRef) => {
      return buttonRef.nativeElement.innerText.toLowerCase().startsWith(key);
    });

    const nextButton = matchingButtons.filter((buttonElement) => {
      return document.activeElement !== buttonElement.nativeElement;
    })[0];

    if (!nextButton) return;
    nextButton.nativeElement.focus();
    this.optionButtons.forEach((buttonRef) => {
      buttonRef.nativeElement.classList.remove('hover:bg-primary');
    });
  }

  selectValue(option: PositionType) {
    if (this.selectedValue === option) return;
    this.selectedValue = option;
    this.valueSet.emit(option);
  }

  getPositionTypeLabel(positionType: PositionType): string {
    return `${getPositionTypeText(positionType)} (${getPositionTypeText(positionType)[0]})`;
  }

  protected readonly getPositionTypeText = getPositionTypeText;
  protected readonly getWidthForInput = getWidthForInput;
}

import { inject, Injectable } from '@angular/core';
import {
  AuditPositionWithFieldIndex,
  ExtractedFields,
  TableAuditPositionRow,
} from '../../models/interfaces';
import { PersistDataService } from '../app-state/persist-data.service';

@Injectable({
  providedIn: 'root',
})
export class ExtractedDataStoreService {
  private persistDataService = inject(PersistDataService);
  private _extractedFields: ExtractedFields = {} as ExtractedFields;
  private auditPositions: AuditPositionWithFieldIndex[] = [];

  constructor() {
    if (this.persistDataService.auditPositions)
      this.auditPositions = this.persistDataService.auditPositions;
    if (this.persistDataService.extractedFields)
      this.extractedFields = this.persistDataService.extractedFields;
  }

  set extractedFields(extractedFields: ExtractedFields) {
    this._extractedFields = extractedFields;
    this.persistDataService.extractedFields = this.extractedFields;
  }

  get extractedFields() {
    return this._extractedFields;
  }

  setAuditPositionArray(
    tableRows: TableAuditPositionRow[],
    documentId: string,
    initial = false,
  ): void {
    this.auditPositions = initial
      ? this.auditPositions
      : this.auditPositions.filter((position) => {
          return position.documentId !== documentId;
        });

    const auditPositions: AuditPositionWithFieldIndex[] = [];
    tableRows.forEach((row) => {
      const obj: AuditPositionWithFieldIndex = {
        description: { value: row.description.value, id: row.description.id },
        from: { value: row.from.value, id: row.from.id },
        to: { value: row.to.value, id: row.to.id },
        originalAmount: { value: row.amount.value, id: row.amount.id },
        tableId: row.tableId ?? '',
        pageId: row.pageId ?? '',
        type: { value: row.positionType.value, id: row.positionType.id },
        documentId: documentId,
      };

      auditPositions.push(obj);
    });

    this.auditPositions = [...this.auditPositions, ...auditPositions];
    this.persistDataService.auditPositions = this.auditPositions;
  }

  get auditPositionArray() {
    return this.auditPositions;
  }
}

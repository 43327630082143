import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TableAuditPositionRow } from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
//if table creation in overlay is confirmed a table is created
export class ExtractedTableCreationService {
  createdTable$: Subject<TableAuditPositionRow[]> = new Subject<TableAuditPositionRow[]>();

  public createTable(tableRows: TableAuditPositionRow[]) {
    this.createdTable$.next(tableRows);
  }
}
